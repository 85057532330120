import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../scss/style.scss';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', () => {
	//initDevTools();
	initStickyNavbar();
	animateAccentLines();
	setTimeout(() => {
		loadAndPlayVideos();
		animateVideoContainer();
		animateHeaderElements();
		animateHeroElements();
		initImageRevealAnimation();
		ScrollTrigger.refresh(); // Refresh ScrollTrigger after all animations are set up
	}, 0); // Defer non-critical work
});

// Function to initialize sticky navbar
function initStickyNavbar() {
	const navbar = document.querySelector('.navbar');
	const header = document.querySelector('header');

	const observer = new IntersectionObserver(
		(entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					navbar.classList.remove('sticky');
				} else {
					navbar.classList.add('sticky');
				}
			});
		},
		{ threshold: [0] }
	);

	observer.observe(header);
}

function loadAndPlayVideos() {
	const videoSources = document.querySelectorAll('video source[data-src]');
	videoSources.forEach((source) => {
		const video = source.closest('video');
		source.src = source.getAttribute('data-src');
		source.removeAttribute('data-src');
		video.load();
		tryAutoPlayVideo(video);
	});
}

function tryAutoPlayVideo(video) {
	video.play().catch((error) => {
		console.error('Error auto-playing video:', error);
	});
}

function animateVideoContainer() {
	gsap.from('.video-container', {
		duration: 0.9,
		opacity: 0,
		delay: 1, // Optional: Add a delay to give the page a moment to stabilize
		ease: 'circ.out',
	});
}

function animateHeaderElements() {
	gsap.from(['.siteLogo', '.navbar .nav-link', '.phone-container > *', '.btn-container > *'], {
		duration: 0.9,
		x: -100,
		opacity: 0,
		delay: 0.5,
		ease: 'circ.out',
	});
}

function animateHeroElements() {
	gsap.from(['.hero .tagline', '.hero h1', '.hero-content', '.hero-buttons'], {
		duration: 0.9,
		x: -100,
		opacity: 0,
		stagger: 0.1,
		delay: 1,
		ease: 'circ.out',
	});
}

function animateAccentLines() {
	gsap.from('.accentLines > *', {
		duration: 1,
		y: '-100%',
		stagger: 0.3,
		ease: 'circ.out',
		delay: 0.2,
	});
}

function initImageRevealAnimation() {
	gsap.fromTo(
		'.reveal img',
		{
			opacity: 0,
			y: 100,
		},
		{
			opacity: 1,
			y: 0,
			scrollTrigger: {
				trigger: '.reveal',
				start: 'top 75%',
				end: 'bottom top',
				toggleActions: 'play none none reverse',
			},
		}
	);
}

document.addEventListener('DOMContentLoaded', function () {
	var button = document.querySelector('.newsletter .fui-btn');
	button.innerHTML = '<i class="fa-light fa-paper-plane"></i>';
});

function initDevTools() {
	// Responsive Breakpoints
	const handleResize = (() => {
		let breakpoint, dimensions;

		const updateDimensions = () => {
			const ww = window.innerWidth;
			const wh = window.innerHeight;

			if (ww < 576) breakpoint = 'XS';
			else if (ww >= 576 && ww < 768) breakpoint = 'SM';
			else if (ww >= 768 && ww < 992) breakpoint = 'MD';
			else if (ww >= 992 && ww < 1200) breakpoint = 'LG';
			else if (ww >= 1200 && ww < 1400) breakpoint = 'XL';
			else breakpoint = 'XXL';

			dimensions = `${ww} x ${wh} | ${breakpoint}`;
			document.getElementById('devTools').innerHTML = dimensions;
		};

		return {
			init: () => {
				document.body.insertAdjacentHTML('beforeend', '<div id="devTools" class="text-uppercase fs-xs"></div>');
				window.addEventListener('resize', updateDimensions);
				window.dispatchEvent(new Event('resize'));
			},
		};
	})();

	handleResize.init();
}
